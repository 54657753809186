import axios from 'axios';
import Parser from 'etap/admin/classes/jsonapi_parser.js';
import Task from "../../admin/classes/models/task";

export default {
    namespaced: true,

    state: {
        tasks: [],
    },

    mutations: {
        removeTasksForProject(state, projectId) {
            state.tasks = state.tasks.filter(task =>{
                return  task.taskable_id !== projectId &&
                        task.taskable_type !== window.MAT.model_map.project;
            });
        },
        setTasks(state, tasks) {
            tasks.forEach(task => {
                const existingTaskIndex = state.tasks.findIndex(existingTask => existingTask.id === task.id);

                if(existingTaskIndex >= 0) {
                    state.tasks.splice(existingTaskIndex, 1, task);
                } else {
                    state.tasks.push(task);
                }
            });
        },
        createTaskForProject(state, projectId) {

            const newTask = new Task({
                taskable_id: projectId,
                taskable_type: window.MAT.model_map.project,
            })

            state.tasks.push(newTask);
        },
        removeNewTaskForProject(state, projectId) {

            const existingNewTaskIndex = state.tasks.findIndex(task => {
                return task.taskable_id === projectId && task.id === null;
            });

            if(existingNewTaskIndex >= 0) {
                state.tasks.splice(existingNewTaskIndex, 1);
            }
        },
        upsertTask(state, task) {
            const existingTaskIndex = state.tasks.findIndex(existingTask => existingTask.id === task.id);

            if(existingTaskIndex >= 0) {
                state.tasks.splice(existingTaskIndex, 1, task);
            } else {
                state.tasks.push(task);
            }
        },
        removeTask(state, task) {
            const existingTaskIndex = state.tasks.findIndex(existingTask => {
                return existingTask.id === task.id
            });

            if(existingTaskIndex >= 0) {
                state.tasks.splice(existingTaskIndex, 1);
            }
        }
    },


    actions: {
        async getTasksForProject({commit}, {projectId, filters}) {
            const params = {
                ...filters,
                include: ['files', 'assignable']
            };

            const response = await axios.get(
                '/api/projects/' + projectId + '/tasks', {
                    params
                }
            );
            const tasks = Parser.parseJSONAPIResponse(response.data);
            commit('removeTasksForProject', projectId);
            commit('setTasks', tasks);
        },
        async deleteTaskForProject({commit}, task) {
            if(!task.id) {
                commit('removeTask', task);
                return;
            }


            const response = await axios.delete(
                '/api/projects/' + task.taskable_id + '/tasks/' + task.id
            );

            commit('removeTask', task);
        }
    },


    getters: {
        tasksForProjectId: (state) => (projectId) => {
            return state.tasks.filter(task => task.taskable_id === projectId);
        }
    }


}
