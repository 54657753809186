<script>
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export default {

    data() {
        return {
            icons: {
                plus: faPlus
            },
            filters: {
                mine: {
                    task_type_id: [],
                    task_status_id: [],
                    assignable_id: window.MAT?.user?.id,
                    include: ['taskable', 'taskable.user', 'assignable', 'files'],
                },
                open: {
                    task_type_id: [],
                    task_status_id: [],
                    assignable: 'unassigned',
                    include: ['taskable', 'taskable.user', 'assignable', 'files'],
                },
                assigned: {
                    task_type_id: [],
                    task_status_id: [],
                    assignable: 'assigned',
                    include: ['taskable', 'taskable.user', 'assignable', 'files'],
                }
            },
            bulkActions: [
                {
                    name: 'update_task_assignee',
                    title: 'Update Task Assignee',
                    type: 'update_assignee',
                    refreshRecords: true,
                    options: {
                        component: 'bulk-update-task-action-type-handler'
                    },
                },
                {
                    name: 'update_task_status',
                    title: 'Update Task Status',
                    type: 'update_task_status',
                    refreshRecords: true,
                    options: {
                        component: 'bulk-update-task-action-type-handler'
                    },
                },
            ],
            initializing: false,
            actionComponent: null,
            actionComponentArguments: {},
            clears: {
                mine: false,
                open: false,
                assigned: false,
            },
            queueRefreshes: {
                mine: false,
                open: false,
                assigned: false,
            },
            showAddTaskForm: false,
        }
    },

    async created() {

        try {

            if(!this.tasksEnabled) {
                return;
            }

            this.initializing = true;
            // get the configured lookup models for tasks but wait to load the tasks themselves
            // until we've initialized the tasks layout view
            await Promise.all([
                this.$store.dispatch('lookup/getLookupForModel', {model: this.taskFeature.options?.task_types}),
                this.$store.dispatch('lookup/getLookupForModel', {model: this.taskFeature.options?.task_statuses}),
                this.$store.dispatch('lookup/getLookupForModel', {model: this.taskFeature.options?.task_states}),
                this.$store.dispatch('form_configurations/getFormConfigurationsByName', [this.taskFeature.options?.tasks_form_configuration]),
                this.$store.dispatch('entity_types/getEntityTypeForName', 'task')
            ]);

            if(
                !this.taskSearchForm &&
                this.taskFeature.options?.tasks_home_search_form
            ) {
                await this.$store.dispatch(
                    'form_configurations/getFormConfigurationsByName',
                    [this.taskFeature.options?.tasks_home_search_form]
                );
            }

        }catch(err) {
            console.log(err);
            window.notify.apiError(err);
        }

        this.initializing = false;

    },

    computed: {
        taskFeature() {
            return window.MAT.features.find(f => f.name === 'tasks');
        },
        tasksEnabled() {
            return this.taskFeature?.enabled;
        },
        taskSearchForm() {
            if(
                !this.taskFeature?.options?.tasks_home_search_form
            ) {
                return null;
            }

            return this.$store.getters['form_configurations/getFormConfigurationByName']
                (this.taskFeature.options.tasks_home_search_form);
        },
        taskStatuses() {
            const statusesModel = this.taskFeature.options?.task_statuses;
            if(!statusesModel) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](statusesModel);
        },
        taskTypes() {
            const taskTypesModels = this.taskFeature.options?.task_types;
            if(!taskTypesModels) {
                return [];
            }

            return this.$store.getters['lookup/getLookupsForModel'](taskTypesModels);
        },
        sorts() {
            if(this.tasksEnabled && this.taskFeature.options?.task_sorts){
                return this.taskFeature.options.task_sorts;
            }

            return  [{
                title: 'Due Date',
                name: 'date_due',
                by: 'date_due',
            },{
                title: 'Most Recently Created',
                name: 'created_at',
                by: 'created_at',
                dir: 'desc'
            },{
                title: 'Most Recently Updated',
                name: 'updated_at',
                by: 'updated_at',
                dir: 'desc'
            }];
        },
        defaultSort() {
            return this.sorts.find(sort => sort.default)?.by || 'date_due';
        }
    },

    methods: {
        updateFilters(updatedForm) {
            for(var key in updatedForm) {
                if(
                    !Object.prototype.hasOwnProperty.call(updatedForm, key)
                ) {
                    continue;
                }

                // ensure that the value we're adding to filters is not tied to the form's data
                let value = JSON.parse(JSON.stringify(updatedForm[key]));
                if(key !== 'assignable_id') {
                    this.filters.mine[key] = value;
                    this.filters.open[key] = value;
                }

                this.filters.assigned[key] = value;
            }
        },
        async runBulkAction({action, selectedRecords}) {
            switch (action.type) {
                case 'update_assignee':
                case 'update_task_status':
                    this.actionComponent = action.options.component;
                    this.actionComponentArguments = {
                        action,
                        selectedRecords
                    }
                    break;
                default:
                    return null;
            }
        },
        closeActionComponent() {
            this.actionComponent = null;
            this.actionComponentArguments = {};
        },
        bulkActionCompleted() {
            const action = this.actionComponentArguments.action;
            window.notify.message('Successfully ' + action.title, 'success');

            this.clears.mine = true;
            this.clears.open = true;
            this.clears.assigned = true;
            this.closeActionComponent()
            if(action.refreshRecords) {
                window.setTimeout(() => {
                    this.queueRefreshes.mine = true;
                    this.queueRefreshes.open = true;
                    this.queueRefreshes.assigned = true;
                }, 3000);
            }
        },
        refreshLists() {
            this.showAddTaskForm = false;
            this.queueRefreshes.mine = true;
            this.queueRefreshes.open = true;
            this.queueRefreshes.assigned = true;

        }
    }
}
</script>
<template>
    <div>

        <div class="shadow-md p-4 flex justify-end">
            <div>
                <button class="button" @click="showAddTaskForm = true">
                    <font-awesome-icon :icon="icons.plus"/>&nbsp;
                    Add Task
                </button>
            </div>
        </div>

        <div class="flex justify-between">
            <div class="p-2 w-2/3" v-if="!initializing">
                <div class="my-4">
                    <h3 class="border-b">My Tasks</h3>
                    <v-grid
                        :use-json-api="true"
                        :allow-refresh="true"
                        :grid-args="{noRecordsText: 'No tasks assigned to you'}"
                        record-url="/api/tasks"
                        :record-url-params="filters.mine"
                        :sorts="sorts"
                        :default-sort="defaultSort"
                        :records-self-select="true"
                        :bulk-actions="bulkActions"
                        @runBulkAction="runBulkAction"
                        :clear-selected-records.sync="clears.mine"
                        :queue-refresh.sync="queueRefreshes.mine"
                        grid-type="divRow"
                        record-type="task-home-search-row"
                    />
                </div>
                <div class="my-4">
                    <h3 class="border-b">Open Tasks</h3>
                    <v-grid
                        :use-json-api="true"
                        :allow-refresh="true"
                        :grid-args="{noRecordsText: 'No open tasks'}"
                        record-url="/api/tasks"
                        :record-url-params="filters.open"
                        :sorts="sorts"
                        :default-sort="defaultSort"
                        :records-self-select="true"
                        :bulk-actions="bulkActions"
                        @runBulkAction="runBulkAction"
                        :clear-selected-records.sync="clears.open"
                        :queue-refresh.sync="queueRefreshes.open"
                        grid-type="divRow"
                        record-type="task-home-search-row"
                    />
                </div>
                <div class="my-4">
                    <h3 class="border-b">Assigned Tasks</h3>
                    <v-grid
                        :use-json-api="true"
                        :allow-refresh="true"
                        :grid-args="{noRecordsText: 'No assigned tasks'}"
                        record-url="/api/tasks"
                        :record-url-params="filters.assigned"
                        :sorts="sorts"
                        :default-sort="defaultSort"
                        :records-self-select="true"
                        :bulk-actions="bulkActions"
                        @runBulkAction="runBulkAction"
                        :clear-selected-records.sync="clears.assigned"
                        :queue-refresh.sync="queueRefreshes.assigned"
                        grid-type="divRow"
                        record-type="task-home-search-row"
                    />
                </div>
                <component
                    v-if="actionComponent"
                    :is="actionComponent"
                    :args="actionComponentArguments"
                    @closeActionComponent="closeActionComponent"
                    @bulkActionCompleted="bulkActionCompleted"
                ></component>
            </div>
            <div class="w-1/3 shadow-md p-2" v-if="taskSearchForm">
                <vue-form
                    v-if="!initializing"
                    :form-config="taskSearchForm"
                    :form-data="{
                        task_type_id: taskTypes.map(t => t.id),
                        task_status_id: taskStatuses.map(s => s.id),
                    }"
                    :pass-thru="true"
                    @updated="updateFilters"
                    :use-json-api="true"
                    :actions="[{'label': 'Search', 'action': 'submitForm', name: 'search'}]"
                />
            </div>
        </div>
        <right-panel v-if="showAddTaskForm" @close="showAddTaskForm = false">
            <template #header>
                <div class="m-4 p-3 shadow-md rounded w-full">
                    <h3 class="">Add Task</h3>
                </div>
            </template>
            <template>
                <add-task-form @task-created="refreshLists" />
            </template>
        </right-panel>
    </div>
</template>
